import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './Image.styles'

const Image = ({ data, hasMarginBottom, ...props }) => {
  const { childImageSharp, fields = {}, description = '' } = data

  if (childImageSharp) {
    return (
      <Styled.Img
        fluid={childImageSharp.fluid && childImageSharp.fluid}
        fixed={childImageSharp.fixed && childImageSharp.fixed}
        hasMarginBottom={hasMarginBottom}
        alt={description}
        {...props}
      />
    )
  }

  if (fields.svg) {
    return (
      <div
        className="svg-image"
        dangerouslySetInnerHTML={{ __html: fields.svg.code }}
        style={{ maxWidth: `${fields.svg.maxWidth}px` }}
      />
    )
  }

  return null
}

Image.defaultProps = {
  hasMarginBottom: false,
}

Image.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    childImageSharp: PropTypes.object,
  }).isRequired,
  hasMarginBottom: PropTypes.bool,
}

export default Image
