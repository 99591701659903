import React from 'react'
import PropTypes from 'prop-types'

import * as Styled from './Hero.styles'

const Hero = ({ type, image, darkText, children, ...props }) => {
  return (
    <Styled.Hero darkText={darkText} {...props}>
      <Styled.HeroWrap>
        <Styled.Content>{children}</Styled.Content>
        <Styled.HeroImage data={image} />
      </Styled.HeroWrap>
    </Styled.Hero>
  )
}

Hero.defaultProps = {
  image: null,
  type: 'Default',
  darkText: true,
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({
    extension: PropTypes.oneOf(['svg', 'png', 'jpg']),
    childImageSharp: PropTypes.object,
  }),
  type: PropTypes.oneOf(['Default', 'Split']),
  darkText: PropTypes.bool,
}

export default Hero
