import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { stripUnit } from 'polished'

import Image from '../Image'
import Section from '../Section'
import fluidRange from '../../utils/fluidRange'

// Top Section wrapper
export const Hero = styled(Section)(
  ({ darkText, theme }) => css`
    position: relative;
    z-index: 0;
    color: ${darkText};
    padding: 0 !important;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 0;
    }
  `
)

// Wrapper for content and hero image
export const HeroWrap = styled(Section)(
  ({ darkText, theme }) => css`
    padding: 0 !important;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    margin: 0 auto;
  `
)

// Text copy and CTA
export const Content = styled.div(
  ({ split, theme }) => css`
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 2% 3% 2% 0;
    align-items: flex-start;
    justify-content: center;

    form input{
      padding: 8px 10px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 2;
      flex-basis: 100%;
      padding: 4% 6%;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex: 2;
      flex-basis: 100%;
      padding: 4% 8%;

      a button{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
      }

      form button{
        margin-right: 0;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
      }
    }
  `
)

// Main Hero image
export const HeroImage = styled(Image)(
  ({ theme, split }) => css`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 2% 0;
    align-self: stretch;

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 2;
      flex-basis: 100%;
      order: -1;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex: 2;
      flex-basis: 100%;
      order: -1;
    }
  `
)
